import { MudrexKyc, init, start } from '@mudrex/kyc';
import '@mudrex/kyc/dist/cjs/index.css';
import LoadingScreen from 'components/shared/LoadingScreen';
import { useBackStackContext } from 'context/BackStackContext';
import { useConfig } from 'context/ConfigContext';
import { useAuth } from 'context/auth/use-auth';
import BankVerification from "pages/kyc/BankVerification";
import { useEffect } from "react";
import { getPlatform } from 'utils/platforms';
import { useWindowSize } from 'utils/useWindowSize';
import { KYC_STATE } from './KycHelper';
import { EventLevel, sendEvent } from 'utils/events/handler';

export const KycWrapper = ({
  user,
  onStatusChange,
  onCompleted,
  presetKycCountry,
  fixedPresetKycCountry,
  externalDigilocker
}) => {
  const windowSize = useWindowSize()
  const config = useConfig()?.config

  const evaluateState = (user) => {
    if (!user.isKycVerified()) {
      return KYC_STATE.KYC
    } else if (!user.hasValidBankAccount()) {
      return KYC_STATE.BANK_ACCOUNT
    }

    return KYC_STATE.COMPLETE
  }

  // The library opens the digilocker in a new tab by default. 
  // False opens it in the same container/tab.
  const handleDigilockerViaLib = externalDigilocker ?? config?.features?.digilocker?.open_external ?? false

  const state = evaluateState(user)

  const { headers } = useAuth()

  const userCountry = user?.getCountry()?.iso3

  const backstack = useBackStackContext()

  const navigateToDigilocker = (url) => {
    if (config?.features?.digilocker?.handle_redirect) {
      window.sessionStorage.setItem("request_url", window.location.href);
      backstack.storeCache();

      window.location.href = url;
      sendEvent("DIGILOCKER_URL", EventLevel.INFO, { url });
      return;
    }

    const targetString = "/kyc/digilocker";
    const urlPaths = [
      headers?.["X-Secret-Key"],
      headers?.["X-Timestamp"],
      headers?.["X-User-Id"],
      headers?.["X-Client-Id"],
    ]
      .filter(Boolean)
      .join("/");

    const finalURL = headers
      ? url.replace(targetString, `${targetString}/${urlPaths}`)
      : url;

    sendEvent("DIGILOCKER_URL", EventLevel.INFO, { url: finalURL });
  };

  useEffect(() => {

    if (!config?.features) {
      return
    }

    const platform = getPlatform().toString()
    const deviceId = platform + "-user"
    init({
      headers: {
        ...headers,
        'X-Platform': platform,
        'X-Device-Id': deviceId,
        'X-Source': 'sdk',
        'X-Request-Id': 123456,
      },
      env: process.env.REACT_APP_STAGE,
      autoInitCountry: userCountry ?? presetKycCountry,
      autoTriggerDigilockerUrl: handleDigilockerViaLib,
      autoUpdateDigilockerStatus: handleDigilockerViaLib,
      onDigilockerUrlGenerated: handleDigilockerViaLib ? () => { } : navigateToDigilocker,
      onKycStatusChange: (status) => {
        if (status?.toUpperCase() === "APPROVED") {
          onCompleted()
        }
        onStatusChange()
      },
      forceIsMobile: true
    })

    start()
  }, [config, handleDigilockerViaLib])

  if (!config) {
    return <LoadingScreen />
  }

  const states = (state) => {
    switch (state) {

      case KYC_STATE.KYC:
        return (<div style={{ width: '100vw', height: windowSize.height, textAlign: 'left' }}>
          <MudrexKyc /></div>)

      case KYC_STATE.BANK_ACCOUNT:
        return (
          <BankVerification
            init={init}
            onStepCompleted={onStatusChange}
          />
        );
      default:
        return <></>
    }
  }

  return (
    states(state)
  )
};